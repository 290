<template>
  <div
      class="defile w-full"
      :style="{ 'font-size': size }"
  >
    <div
        v-for="(item, index) in donnee"
        :key="index"
        class="cursor-pointer item w-full"
    >
      <div
          class="pt-4 pb-4 text-left pl-4 pr-4 flex items-center w-full"
          @click="selectItem(item)"
      >
        <nameLabel
            class="mr-4"
            height="30px"
            width="30px"
            size="12px"
            :first="item[0]"
            :second="item[1]"
            v-if="first !== null"
        />
        <span v-if="!displayKey">{{ item }}</span>
        <span v-if="displayKey">{{ item[displayKey] }}</span>
      </div>

      <div
          v-if="index < option.length - 1"
          class="border border-gray"
      />
    </div>

    <div
        v-if="donnee.length === 0 && !addOption"
        class="cursor-pointer"
    >
      <div class="md:p-5 md:pt-5 md:pb-5 pt-1 pb-1 pl-5 pr-5 grayColor">
        Recherche introuvable
      </div>
    </div>

    <div
        v-if="donnee.length === 0 && addOption"
        class="cursor-pointer flex items-center pr-6"
    >
      <div class="md:p-5 md:pt-5 md:pb-5 pt-1 pb-1 pl-5 pr-5 grayColor w-4/5">
        {{ value }}
      </div>

      <boutton
          :label="addOption"
          background="#c0cadb80"
          color="#146cf1"
          radius="5px"
          size="12px"
          height="30px"
          class="w-1/5"
          @info="addNewOption"
          :charge="charge"
      />
    </div>
  </div>
</template>

<script>
import nameLabel from "../add/nameLabel";
import boutton from "../add/button";

export default {
  components: {
    nameLabel,
    boutton
  },

  props: {
    option: {
      type: Array,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "14px",
    },
    first: {
      type: String,
      default: null,
    },
    second: {
      type: String,
      default: null,
    },
    displayKey: {
      type: String,
      default: null,
    },
    addOption: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      charge: false
    };
  },

  computed: {
    donnee: function () {
      let retour = this.option;
      if (this.value !== null) {
        var authorNameSearchString = this.value;
        authorNameSearchString = authorNameSearchString.toLowerCase();
        retour = retour.filter((item) => {
          if (!this.displayKey){
            if (
                item !== undefined &&
                item
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .indexOf(
                        authorNameSearchString
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                    ) !== -1
            ) {
              return item;
            }
          } else {
            if (
                item[this.displayKey] !== undefined &&
                item[this.displayKey]
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .indexOf(
                        authorNameSearchString
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                    ) !== -1
            ) {
              return item;
            }
          }

        });
      }

      return retour;
    },
  },

  methods: {
    selectItem(item) {
      this.$emit("info", item);
    },

    addNewOption() {
      this.charge = true
      this.$emit('adding', this.value)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";
.defile {
  background: #ffffff;
  border: 0.5px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 12px;
  font-style: normal;
  line-height: 21px;
  color: #000000;
  font-family: $font-default;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 999;
  position: absolute;
}
.defile::-webkit-scrollbar {
  display: none;
}
.item:hover {
  background-color: $hover-side;
}
.grayColor {
  color: #c3c3c3;
}
@media screen and (max-width: 700px) {
  .defile {
    font-size: 17px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 770px) {
  .defile {
    font-size: 18px;
  }
}
@media screen and (max-width: 769px) and (min-width: 701px) {
  .defile {
    font-size: 16px;
  }
}
</style>
